/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-12-19 17:18:08
 * @LastEditors: alex.yang
 * @LastEditTime: 2024-07-10 14:30:02
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'; 
import 'element-ui/lib/theme-chalk/index.css';
import menu from './internal/menuJson'

import './assets/elementCss/dialogCss.css'
import axios from 'axios';
import Print from 'vue-print-nb'

Vue.use(Print)
Vue.prototype.$menu = menu
Vue.prototype.$axios = axios;

Vue.use(ElementUI);
Vue.config.productionTip = false

// 过滤器
import * as custom from './utils/util'
Object.keys(custom).forEach(key => {
  Vue.filter(key, custom[key])
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
